import React, { useState, useEffect } from "react";
import { Row, Col, Container, Breadcrumb, Form, Card, Alert } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { isUserSignedIn, isUserAdmin } from "../components/accountManagement";
import { SideBarOptions, accountMenuLinks, TopMenuOptions } from "../components/genericMenu";
import { updateUserPersonalInfo } from "../actions/user-action";
import { store } from "../redux-store";
import { callAPI } from "../components/api";
import { refreshUserInfo } from "../components/accountManagement";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";

const uri = "/account/";

const MyAccountPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);
  const [extraUserData, setExtraUserData] = useState({
    first_name: "",
    last_name: "",
  });
  const [userInfoRefreshed, setUserInfoRefreshed] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);

  useEffect(() => {
    const localRefreshUserInfo = async () => {
      //console.log('account: localRefreshUserInfo - accessToken='+userData.accessToken);
      setBannerMessage('');
      refreshUserInfo(userData, store).then((result) => {
        console.log("result.status=" + result.status);
        if (result.status === "success") {
          setExtraUserData({
            first_name: result.data.first_name,
            last_name: result.data.last_name,
          });
        } else {
          setBannerMessage("Error: " + result.message);
        }
      });
    };

    console.log("in refresh user");
    console.log("userInfoRefreshed=" + userInfoRefreshed);

    if (userInfoRefreshed) {
      return;
    }
    setUserInfoRefreshed(true);
    localRefreshUserInfo();
  }, [userData, userInfoRefreshed]);

  console.log("userData.userName=" + userData.userName);

  const updatePersonalInfo = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let data = {
      email: form.email.value,
      first_name: form.firstname.value,
      last_name: form.lastname.value,
    };
    setBannerMessage("");

    var result = await callAPI("/users/me", "PATCH", data, userData, store, true);
    if (result.status === "success") {
      var is_verified = userData.isVerified;
      if (is_verified && userData.userName !== form.email.value) {
        is_verified = false;
      }
      store.dispatch(updateUserPersonalInfo(form.email.value, is_verified));
      setBannerMessage("Saved.");
    } else {
      if (result.apiStatus === 400) {
        if (result.data.detail === "UPDATE_USER_EMAIL_ALREADY_EXISTS") {
          setBannerMessage("Email is already registered by another user");
        } else {
          setBannerMessage(result.data.detail);
        }
      } else {
        console.error("Error:", result.message);
        setBannerMessage("Error: " + result.message);
      }
    }
  };

  const sendVerificationEmail = async () => {
    setBannerMessage("");
    var result = await callAPI("/users/request-verify-token", "GET", null, userData, store, true);
    if (result.status === "success") {
      setBannerMessage(
        "Verification email sent. An email should arrive within a few minutes. " +
          "Be sure to check your spam email folder if you do not see it."
      );
      setVerificationSent(true);
    } else {
      if (result.apiStatus === 400) {
        if (result.data.detail === "VERIFY_USER_ALREADY_VERIFIED") {
          setBannerMessage("Email has already been verified.");
          store.dispatch(updateUserPersonalInfo(userData.userName, true));
        } else {
          setBannerMessage(result.data.detail);
        }
      } else {
        console.error("Error:", result.message);
        setBannerMessage("Error: " + result.message);
      }
    }
  };

  return (
    <Layout pageInfo={{ pageName: "account" }} sideBarOptions={SideBarOptions(accountMenuLinks, uri, "My Account")}>
      <Seo title="Personal Info" keywords={[`account`]} description="Account page" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={accountMenuLinks} currentLink={uri} menuTitle="My Account" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Personal Info</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <Container fluid>
            <Card className="personal-info-card">
              <Card.Body>
                <ModalHeader>
                  <ModalTitle>Personal Info</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  {bannerMessage === "" ? (
                    <></>
                  ) : (
                    <Alert className="ms-2 me-2 mt-2" variant="warning">
                      {bannerMessage}
                    </Alert>
                  )}
                  <Form noValidate onSubmit={updatePersonalInfo} id="personal-info-form" autoComplete="off">
                    <div className="mb-5">We'll never share your personal information with anyone else.</div>
                    <Row className="mb-2">
                      <Col sm={3}>
                        <Form.Label htmlFor="inputEmail" className="form-label">
                          <b>Email Address</b>
                        </Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          required
                          type="email"
                          className="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          defaultValue={userData.userName}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm={3}></Col>
                      <Col sm={9}>
                        {userData.isVerified ? (
                          <Alert className="personal-info-email-alert" variant="success">
                            Email address has been verified
                          </Alert>
                        ) : (
                          <>
                            <Alert className="personal-info-email-alert mb-1" variant="secondary">
                              Email address has not been verified
                            </Alert>
                            <button
                              type="button"
                              className={"btn btn-primary" + (verificationSent ? " disabled" : "")}
                              onClick={sendVerificationEmail}
                            >
                              Send Verification Email
                            </button>
                          </>
                        )}
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm={3}>
                        <Form.Label htmlFor="inputEmail" className="form-label">
                          <b>First Name</b>
                        </Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control className="form-control" id="firstname" defaultValue={extraUserData.first_name} />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm={3}>
                        <Form.Label htmlFor="inputEmail" className="form-label">
                          <b>Last Name</b>
                        </Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control className="form-control" id="lastname" defaultValue={extraUserData.last_name} />
                      </Col>
                    </Row>
                    {isUserAdmin(userData) ? (
                      <Row className="mb-3">
                        <Col sm={3}>
                          <Form.Label htmlFor="roles" className="form-label">
                            <b>Roles</b>
                          </Form.Label>
                        </Col>
                        <Col sm={9}>
                          <Form.Control
                            disabled
                            className="form-control"
                            id="roles"
                            defaultValue={userData.userRoles}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </Form>
                </ModalBody>
              </Card.Body>
            </Card>
          </Container>
        ) : (
          <>Not logged in {navigate("/account/signin/")}</>
        )}
      </Container>
    </Layout>
  );
};

export default MyAccountPage;
